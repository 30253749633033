/**
 * Komponente ´Details´
 */

import {
	needJquery,
	noop
}                     from '../../js/utils/index';
import {extend}       from '../../js/utils/extend';
import {focusVisible} from '../../js/utils/focus-visible';

import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';
import Data           from '../../js/dom/data';

// -------
// Private
// -------

const $ = needJquery();

const COMPONENT_NAME    = 'details';
const COMPONENT_KEY     = `ifab.${COMPONENT_NAME}`;
// const COMPONENT_API_KEY = `${COMPONENT_KEY}.data-api`;

const SELECTOR_COMPONENT = `[data-c="${COMPONENT_NAME}"]`;
// const SELECTOR_TOGGLE    = `.${COMPONENT_NAME}__header`;
const SELECTOR_PANEL     = `.${COMPONENT_NAME}__body`;

const DEFAULTS = {
	container: document.body,
	onHidden: noop,
	onHide  : noop,
	onShow  : noop
};

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	if(Data.get(element, `${COMPONENT_KEY}.initialized`)) {
		return element;
	}

	// const toggle = SelectorEngine.findOne(SELECTOR_TOGGLE, element);
	const panel  = SelectorEngine.findOne(SELECTOR_PANEL, element);

	// Events mit jQuery erweitern :(.
	// Sonstige Möglichkeiten erfordern komplette separate Initialisierungen.
	$(panel)
		.on('show.bs.collapse', function(ev) {
			panel.hidden = false;

			Manipulator.addClass(element, '_active');

			o.onShow(ev);
		})
		.on('hide.bs.collapse', function(ev) {
			Manipulator.removeClass(element, '_active');

			o.onHide(ev);
		})
		.on('hidden.bs.collapse', function(ev) {
			panel.hidden = true;

			o.onHidden(ev);
		});

	focusVisible.observe(element);

	// Initialisierungsstatus setzen.
	Data.set(element,`${COMPONENT_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

/**
 * Single ´Detail´ initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 */
const single = (element, o = {}) => {
	return (element) ? render(element, extend({}, DEFAULTS, o)) : null;
};

/**
 * Alle vorhandenen ´Details´ initialisieren.
 *
 * @param {Object} o
 */
const init = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(SELECTOR_COMPONENT, _o.container);

	for (const element of collection) {
		const tmp = render(element, _o);
	}
};

// Export
export default {
	init  : ($ ? init : noop),
	single: ($ ? single : noop)
};
