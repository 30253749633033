// ------------
// Requirements
// ------------

import {
	isNode,
	isObject
} from './is';

// ------
// Public
// ------

/**
 * Objekte miteinander mergen/erweitern.
 *
 * @param args
 * @returns {Object}
 */
const extend = (...args) => {
	const to       = new Object(args[0]);
	const noExtend = ['__proto__', 'constructor', 'prototype'];

	for (let i = 1; i < args.length; i += 1) {
		const nextSource = args[i];

		if (nextSource !== undefined && nextSource !== null && !isNode(nextSource)) {
			const keysArray = Object.keys(new Object(nextSource)).filter((key) => noExtend.indexOf(key) < 0);

			for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex += 1) {
				const nextKey = keysArray[nextIndex];
				const desc    = Object.getOwnPropertyDescriptor(nextSource, nextKey);

				if (desc !== undefined && desc.enumerable) {
					if (isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
						if (nextSource[nextKey].__swiper__) {
							to[nextKey] = nextSource[nextKey];
						} else {
							extend(to[nextKey], nextSource[nextKey]);
						}
					} else if (!isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
						to[nextKey] = {};

						if (nextSource[nextKey].__swiper__) {
							to[nextKey] = nextSource[nextKey];
						} else {
							extend(to[nextKey], nextSource[nextKey]);
						}
					} else {
						to[nextKey] = nextSource[nextKey];
					}
				}
			}
		}
	}

	return to;
};

// Export
export {
	extend
};
