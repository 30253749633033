/**
 * Komponente ´Tab group´
 */

/* eslint-env es6 */

import {
	getElementFromSelector,
	needJquery,
	noop,
	triggerReflow
}                       from '../../js/utils/index';
import {extend}         from '../../js/utils/extend';
import {getOffset}      from '../../js/utils/offset';
import {scrollIntoView} from '../../js/utils/scroll';
import {focusVisible}   from '../../js/utils/focus-visible';

import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';
import Data           from '../../js/dom/data';

// -------
// Private
// -------

const $ = needJquery();

const COMPONENT_NAME    = 'tabgroup';
const COMPONENT_KEY     = `ifab.${COMPONENT_NAME}`;
// const COMPONENT_API_KEY = `${COMPONENT_KEY}.data-api`;

const SELECTOR_COMPONENT  = `[data-c="${COMPONENT_NAME}"]`;
const SELECTOR_NAV        = `.tab-group__nav`;
const SELECTOR_TABS_GROUP = `.tab-group__tabs`;
const SELECTOR_TABS       = `.tab`;

const DEFAULTS = {
	container     : document.body,
	onHidden      : noop,
	onHide        : noop,
	onShow        : noop,
	scrollBehavior: 'smooth',
	showIndicator : true
};

/**
 * Rückgabe des aktiven Tab-Element.
 *
 * @param {HTMLElement, Element} tabgroup
 */
const getActiveTab = (tabgroup) => {
	const tabs = Data.get(tabgroup, `tabs`);

	return tabs.find((tab) => {
		return Manipulator.getAria(tab, 'selected') === true;
	});
};

/**
 * Positioierung des Indikators zum aktiven Tab-Element.
 *
 * @param {HTMLElement, Element} tabgroup
 * @param {HTMLElement, Element} [tabOverwrite = null]
 */
const repositionIndicator = (tabgroup, tabOverwrite = null) => {
	const indicator = Data.get(tabgroup, 'indicator');
	const placement = Data.get(tabgroup, 'placement');
	const nav       = Data.get(tabgroup, 'nav');
	const tab       = tabOverwrite || getActiveTab(tabgroup);

	if (!tab || !indicator) {
		return;
	}

	const width  = tab.clientWidth;
	const height = tab.clientHeight;
	const offset = getOffset(tab, nav);
	const top    = offset.top + nav.scrollTop;
	const left   = offset.left + nav.scrollLeft;

	switch (placement) {
		case 'vertical':
			indicator.style.width     = `${width}px`;
			indicator.style.height    = 'auto';
			indicator.style.transform = `translateX(${left}px)`;
			break;

		case 'horizontal':
			indicator.style.width     = 'auto';
			indicator.style.height    = `${height}px`;
			indicator.style.transform = `translateY(${top}px)`;
			break;
	}
};

/**
 * Bei einigen Ausrichtungen wird der Indikator animiert während sich seine
 * Position aufgrund der Größenänderung der Kompnente ändert.
 * Der Aufruf dieser Methode verhindert das die Animation während der
 * Größenänderung ausgeführt wird ... was etwas "natürlicher" erscheint.
 *
 * @param {HTMLElement, Element} tabgroup
 */
const preventIndicatorTransition = (tabgroup) => {
	const indicator = Data.get(tabgroup, 'indicator');

	if (indicator) {
		const val = indicator.style.transition;

		indicator.style.transition = 'none';

		requestAnimationFrame(() => {
			indicator.style.transition = val;
		});
	}
};

/**
 * Anzeige des Indikators aktualisieren.
 *
 * @param {HTMLElement, Element} tabgroup
 */
const syncIndicator = (tabgroup) => {
	const indicator = Data.get(tabgroup, 'indicator');

	if (indicator) {
		const tab = getActiveTab(tabgroup);

		if (tab) {
			indicator.style.display = 'block';

			repositionIndicator(tabgroup);
		} else {
			indicator.style.display = 'none';
		}
	}
};

/**
 * @param {HTMLElement} tabgroup
 * @param {Object} o
 */
const render = (tabgroup, o) => {
	// Wurde Container schon initialisiert?
	if (Data.get(tabgroup, `${COMPONENT_KEY}.initialized`)) {
		return;
	}

	const placement = (tabgroup.classList.contains('-tabs-left') || tabgroup.classList.contains('-tabs-right')) ? 'horizontal' : 'vertical';
	const nav       = SelectorEngine.findOne(SELECTOR_NAV, tabgroup);
	const tabsGroup = SelectorEngine.findOne(SELECTOR_TABS_GROUP, tabgroup);
	const tabs      = SelectorEngine.find(SELECTOR_TABS, tabgroup);

	Data.set(tabgroup, `options`, o);
	Data.set(tabgroup, `placement`, placement);
	Data.set(tabgroup, `nav`, nav);
	Data.set(tabgroup, `tabsGroup`, tabsGroup);
	Data.set(tabgroup, `tabs`, tabs);

	if (o.showIndicator) {
		const indicator = Manipulator.createElementFrom('<div class="tab-group__indicator"/>');

		Data.set(tabgroup, `indicator`, indicator);

		Manipulator.elementAppend(indicator, tabsGroup);
	}

	const observerResize = new ResizeObserver(() => {
		if (o.showIndicator) {
			preventIndicatorTransition(tabgroup);
			syncIndicator(tabgroup);
		}

		const activeTab = getActiveTab(tabgroup);

		if(typeof activeTab !== 'undefined') {
			scrollIntoView(getActiveTab(tabgroup), nav, 'horizontal', o.scrollBehavior);
		}
	});

	// eslint-disable-next-line unicorn/no-array-for-each
	tabs.forEach(tab => {
		// Events mit jQuery erweitern :(.
		// Sonstige Möglichkeiten erfordern komplette separate Initialisierungen.
		$(tab)
			.on('show.bs.tab', function(ev) {
				const panel = getElementFromSelector(ev.target);

				repositionIndicator(tabgroup, ev.target);

				if (panel) {
					panel.hidden = false;
					triggerReflow(panel);

					Manipulator.setAria(panel, 'selected', true);
					Manipulator.setAria(panel, 'hidden', false);
				}
			})
			.on('shown.bs.tab', function(ev) {
				// syncIndicator(tabgroup);

				// if (placement === 'vertical') {
				// 	setTimeout(() => scrollIntoView(ev.target, nav, 'horizontal'));
				// }
			})
			.on('hide.bs.tab', function(ev) {
				const panel = getElementFromSelector(ev.target);

				if (panel) {
					panel.hidden = true;

					Manipulator.setAria(panel, 'selected', false);
					Manipulator.setAria(panel, 'hidden', true);
				}
			});

		focusVisible.observe(tab);
	});

	observerResize.observe(nav);

	// Initialisierungsstatus setzen.
	Data.set(tabgroup, `${COMPONENT_KEY}.initialized`, true);

	return tabgroup;
};

// -------
// Public
// -------

/**
 * Single ´TabGroup´ initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 */
const single = (element, o = {}) => {
	return (element) ? render(element, extend({}, DEFAULTS.options, o)) : null;
};

/**
 * Alle vorhandenen ´TabGroup´ initialisieren.
 *
 * @param {Object} o
 */
const init = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(SELECTOR_COMPONENT, _o.container);

	for (const tabgroup of collection) {
		const inst = render(tabgroup, _o);
	}
};

// Export
export default {
	init  : ($ ? init : noop),
	single: ($ ? single : noop)
};
