/**
 * Footer
 */

import {observeInView} from '../../shared/js/utils/observer';

import SelectorEngine from '../../shared/js/dom/selector-engine';
import Manipulator    from '../../shared/js/dom/manipulator';

// -------
// Privat
// -------

const globalScrollTop = SelectorEngine.findOne('[data-scroll-top="global"]');

// -------
// Public
// -------

const footer = () => {
	const footer = SelectorEngine.findOne('#page-footer');

	//
	// Überwachung wann der Footer im ´Viewport´ erscheint.
	//

	// Observer initialisieren.
	observeInView(footer, {
		onEnterViewport: () => {
			if (globalScrollTop) {
				Manipulator.addClass(globalScrollTop, '-invert');
			}
		},
		onLeaveViewport: () => {
			if (globalScrollTop) {
				Manipulator.removeClass(globalScrollTop, '-invert');
			}
		},
		observer       : {
			threshold: 0
		}
	});
};

// Export
export default footer;
