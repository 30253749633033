/**
 * Navigation
 */

import {needJquery} from '../../shared/js/utils/index';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../shared/js/utils/scroll';

import SelectorEngine from '../../shared/js/dom/selector-engine';
import Manipulator    from '../../shared/js/dom/manipulator';
import EventHandler   from '../../shared/js/dom/event-handler';

// -------
// Private
// -------

const $        = needJquery();
const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		useOverlay : true,
		onShow     : function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrollen des `<body/>` sperren
			lockBodyScrolling(element);

			Manipulator.addClass(trigger, '-active');
		},
		onAfterHide: function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrolen des `<body/>` entsperren.
			unlockBodyScrolling(element);

			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', function(event) {
		if(!trigger.classList.contains('-active')) {
			drawer.show();
		} else {
			drawer.hide();
		}
	});
};

/**
 * Einblenden Untermenüs.
 */
const setupNavigation = () => {
	const uls = SelectorEngine.find('ul', pageMenu);
	const settings = {
		speedOpen : 250,
		speedClose: 200
	};

	for (const ul of uls) {
		// Anker des Elternelemente zum aktuellen `<ul/>`.
		const anchor = SelectorEngine.prev(ul, 'a')[0];

		if (anchor) {
			EventHandler.on(anchor, 'openMenu.ifab.navigation', function(ev, o) {
				const ul       = SelectorEngine.next(ev.delegateTarget, 'ul')[0];
				const parentLi = SelectorEngine.parents(ev.delegateTarget, 'li')[0];

				Manipulator.addClass(parentLi, '_opened');

				if($) {
					$(ul).slideDown((typeof ev.speed !== 'undefined') ? ev.speed : settings.speedOpen, function() {
						ul.style.display = 'flex';
					});
				}
			});

			EventHandler.on(anchor, 'closeMenu.ifab.navigation', function(ev, o) {
				const ul       = SelectorEngine.next(ev.delegateTarget, 'ul')[0];
				const parentLi = SelectorEngine.parents(ev.delegateTarget, 'li')[0];

				Manipulator.removeClass(parentLi, '_opened');

				if ($) {
					$(ul).slideUp((typeof ev.speed !== 'undefined') ? ev.speed : settings.speedClose);
				}
			});

			EventHandler.on(anchor, 'click.ifab.navigation', function(ev) {
				const parentLi       = SelectorEngine.parents(ev.delegateTarget, 'li')[0];
				const hasOpenedClass = parentLi.classList.contains('_opened');
				const action         = (hasOpenedClass ? 'close' : 'open');

				ev.preventDefault();

				// Ein Klick auf Eintrag mit Untermenü öffnet Untermenü,
				// zweiter Klick springt zur URL!
				if (!hasOpenedClass) {
					// ev.preventDefault();

					// Untermenu öffnen
					EventHandler.trigger(ev.delegateTarget, 'openMenu.ifab.navigation');
				} else {
					// Untermenu schließen
					EventHandler.trigger(ev.delegateTarget, 'closeMenu.ifab.navigation');
				}

				// EventHandler.trigger(ev.delegateTarget, `${action}Menu.ifab.navigation`);
			});
		}
	}

	const activeItems = SelectorEngine.find('ul li._active > a', pageMenu);

	for (const item of activeItems) {
		EventHandler.trigger(item, 'openMenu.ifab.navigation', {
			speed : 0
		});
	}
};

// -------
// Public
// -------

const navigation = () => {
	setupDrawer();

	setupNavigation();
};

// Export
export default navigation;
