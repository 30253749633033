import {isObject, isElement, isNode} from './is';

// -------
// Private
// -------

const MAX_UID                 = 1000000;
const MULTIPLIER_MILLISECONDS = 1000;
const TRANSITION_END          = ['transitionend', 'webkitTransitionEnd'];

const DOMContentLoadedCb = [];

/**
 * @param element
 * @returns {null|*}
 */
const getSelector = element => {
	let selector = element.getAttribute('data-target') || element.getAttribute('data-bs-target') || element.getAttribute('aria-labelledby');

	if (!selector && element.getAttribute('aria-controls')) {
		 selector = '#' + element.getAttribute('aria-controls');
	}

	if (!selector || selector === '#') {
		let href = element.getAttribute('href');

		if (!href || !href.includes('#')) {
			return null;
		}

		// Angaben von kompletten URL's beachten.
		if (href.includes('#') && !href.startsWith('#')) {
			href = `#${href.split('#')[1]}`;
		}

		selector = href && href !== '#' ? href.trim() : null;
	}

	return selector;
};

// -------
// Public
// -------

/**
 * Platzhalterfunktion.
 */
const noop = () => {};

/**
 * Aktuelles Datum.
 *
 * @returns {number}
 */
const now = () => {
	return Date.now();
};

/**
 * ´Unique ID´ mit Präfix generieren.
 *
 * @param {string} prefix
 * @returns {string}
 */
const getUid = prefix => {
	do {
		prefix += Math.floor(Math.random() * MAX_UID);
	} while (document.getElementById(prefix));

	return prefix;
};

const onDOMContentLoaded = cb => {
	if (document.readyState === 'loading') {
		// add listener on the first call when the document is in loading state
		if (!DOMContentLoadedCb.length) {
			document.addEventListener('DOMContentLoaded', () => {
				for (const cb of DOMContentLoadedCb) {
					cb();
				}
				// DOMContentLoadedCb.forEach(cb => cb());
			});
		}

		DOMContentLoadedCb.push(cb);
	} else {
		cb();
	}
};

/**
 * Prüfe auf Vorhandensein von jQuery.
 *
 * @returns {jQuery|null}
 */
const getJquery = () => {
	const {jQuery} = window;

	if (jQuery) {
		return jQuery;
	}

	return null;
};

/**
 * Prüfe auf Vorhandensein von jQuery inkl. Fehlerausgabe.
 *
 * @returns {jQuery|null}
 */
const needJquery = () => {
	const jQ                = getJquery();
	const {jQueryIsMissing} = window;

	// Fehlermeldung nur einmal ausgeben!
	if (!jQ && !jQueryIsMissing && processEnv === 'development') {
		// eslint-disable-next-line no-console
		console.error(`Unfortunately, the current environment still requires jQuery`);

		window.jQueryIsMissing = true;
	}

	return jQ;
};

/**
 *
 * @param element
 * @returns {string|null}
 *
const getSelectorFromElement = element => {
	const selector = getSelector(element);

	if (selector) {
		return document.querySelector(selector) ? selector : null;
	}

	return null;
};*/

/**
 *
 * @param element
 * @returns {HTMLElement|null}
 */
const getElementFromSelector = element => {
	const selector = getSelector(element);

	return selector ? document.querySelector(selector) : null;
};

/**
 * Referenz zum ´Shadow root´ eines Elementes bestimmen.
 *
 * @param {HTMLElement|Node|ParentNode} element
 * @returns {ShadowRoot|null}
 */
const getShadowRoot = element => {
	if (!document.documentElement.attachShadow) {
		return null;
	}

	if (typeof element.getRootNode === 'function') {
		const root = element.getRootNode();

		return root instanceof ShadowRoot ? root : null;
	}

	if (element instanceof ShadowRoot) {
		return element;
	}

	// when we don't find a shadow root
	if (!element.parentNode) {
		return null;
	}

	return getShadowRoot(element.parentNode);
};

/**
 * Animationsdauer eines Elementes bestimmen.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
const getTransitionDuration = element => {
	if (!element) {
		return 0;
	}

	// Lies die Animationsdauer und -verzögerung des Elementes aus.
	let {transitionDuration, transitionDelay} = window.getComputedStyle(element);

	const floatTransitionDuration = Number.parseFloat(transitionDuration);
	const floatTransitionDelay    = Number.parseFloat(transitionDelay);

	// 0 zurückgeben wenn Animationsdauer und -verzögerung nicht gefunden wurde.
	if (!floatTransitionDuration && !floatTransitionDelay) {
		return 0;
	}

	// Gibt es mehrere Angaben zu einer Animationsdauer, dann nimm die erste.
	transitionDuration = transitionDuration.split(',')[0];
	transitionDelay    = transitionDelay.split(',')[0];

	return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * MULTIPLIER_MILLISECONDS;
};

/**
 * Native Elementreferenz, jQuery wäre `$('#element')[0]`, zurückgeben.
 *
 * @param {HTMLElement|jQuery} element
 * @returns {HTMLElement|null}
 */
const testNativeElement = element => {
	if (isElement(element)) {
		return element.jquery ? element[0] : element;
	}

	return null;
};

/**
 * Restart a CSS Animation With JavaScript.
 *
 * @param {HTMLElement} element
 * @return void
 *
 * @see https://www.charistheo.io/blog/2021/02/restart-a-css-animation-with-javascript/#restarting-a-css-animation
 */
const triggerReflow = element => {
	// eslint-disable-next-line no-unused-expressions
	element.offsetHeight;
};

/**
 * TransitionEnd-Event eines Elementes triggern.
 *
 * @param {HTMLElement} element
 */
const triggerTransitionEnd = element => {
	TRANSITION_END.forEach((event) => {
		element.dispatchEvent(new Event(event));
	});
};

/**
 * Ausführung einer Funktion.
 *
 * @param {function} callback
 */
const execute = callback => {
	if (typeof callback === 'function') {
		callback();
	}
};

/**
 * Funktion nach Elementanimation ausführen.
 *
 * @param {function} callback
 * @param {HTMLElement} transitionElement
 * @param {boolean} [waitForTransition=true]
 */
const executeAfterTransition = (callback, transitionElement, waitForTransition = true) => {
	if (!waitForTransition) {
		execute(callback);

		return;
	}

	const durationPadding  = 5;
	const emulatedDuration = getTransitionDuration(transitionElement) + durationPadding;

	// Status EventListener.
	let called = false;

	const handler = ({target}) => {
		if (target !== transitionElement) {
			return;
		}

		// Status EventListener aktualisieren.
		called = true;

		// EventListener entfernen.
		TRANSITION_END.forEach((event) => {
			transitionElement.removeEventListener(event, handler);
		});

		// Callback ausführen.
		execute(callback);
	};

	// EventListener anbinden.
	TRANSITION_END.forEach((event) => {
		transitionElement.addEventListener(event, handler);
	});

	// Wurde der obige EventListener nicht ausgelöst, dann starte ihn manuell.
	setTimeout(() => {
		if (!called) {
			triggerTransitionEnd(transitionElement);
		}
	}, emulatedDuration);
};

/**
 * Elementposition auslesen.
 *
 * @param {(HTMLElement|Element|EventTarget)} el
 * @returns {Object}
 */
const getPosition = (el) => {
	return {
		top : el.offsetTop,
		left: el.offsetLeft
	};
};

// Export
export {
	execute,
	executeAfterTransition,
	getJquery,
	needJquery,
	getSelector,
	getElementFromSelector,
	getShadowRoot,
	getTransitionDuration,
	getPosition,
	getUid,
	noop,
	now,
	onDOMContentLoaded,
	testNativeElement,
	triggerReflow,
	triggerTransitionEnd
};
