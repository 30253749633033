import VendorsFancybox from './fancybox/fancybox';

const vendors = () => {
	/* eslint-disable no-console */
	if (processEnv === 'development') {
		console.log('Vendors');
	}
	/* eslint-enable no-console */

	// Fancybox
	VendorsFancybox();
};

export default vendors;
