/**
 * Komponente ´Spinner´
 */

import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';

// ------
// Public
// ------

/**
 * Spinner-Element einem Element hinzufügen.
 *
 * @param {HTMLElement|Node} target
 */
const addTo = (target) => {
	const spinner = Manipulator.createElementFrom('<span/>');

	Manipulator.addClass(spinner, 'spinner');
	Manipulator.setAria(spinner, 'busy', 'true');
	Manipulator.setAria(spinner, 'live', 'polite');

	Manipulator.elementAppend(spinner, target);
};

/**
 * Spinner-Element von einem Element entfernen.
 *
 * @param {HTMLElement|Node} target
 */
const removeFrom = (target) => {
	const spinner = SelectorEngine.find('.spinner', target);

	for (const element of spinner) {
		element.remove();
	}
};

// Export
export default {
	addTo     : addTo,
	removeFrom: removeFrom
};
