// Simuliert das Verhalten `:focus-visible` auf einem Element, schaltet eine
// CSS-Klasse `-focus-visible` um, indem auf bestimmte Tastatur- und Mausevents
// geachtet wird.
//
// Wird zukünftig wieder entfernt wenn ein beserer Support für `:focus-visible`
// vorhanden ist (https://caniuse.com/#search=focus-visible).

const KEYS  = [
	'Tab',
	'ArrowUp',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
	'Home',
	'End',
	'PageDown',
	'PageUp'
];

const listeners = new WeakMap();

const observe = (element) => {
	const is    = (event) => {
		if (KEYS.includes(event.key)) {
			element.classList.add('_focus-visible');
		}
	};
	const isNot = () => element.classList.remove('_focus-visible');

	listeners.set(element, {is, isNot});

	element.addEventListener('keydown', is);
	element.addEventListener('keyup', is);
	element.addEventListener('mousedown', isNot);
	element.addEventListener('mousedown', isNot);
};

const unobserve = (element) => {
	const {is, isNot} = listeners.get(element);

	element.classList.remove('_focus-visible');

	element.removeEventListener('keydown', is);
	element.removeEventListener('keyup', is);
	element.removeEventListener('mousedown', isNot);
	element.removeEventListener('mousedown', isNot);
};

export const focusVisible = {
	observe,
	unobserve
};
