/**
 * Main(-JS-File)
 */

import {getRootVar} from '../shared/js/utils/rootvar';

import SelectorEngine from '../shared/js/dom/selector-engine';
import Manipulator    from '../shared/js/dom/manipulator';

import components   from './components/components';
import initial      from './initial/initial';
import header       from './layout/header';
import footer       from './layout/footer';
import navigation   from './layout/navigation';
import vendors      from './vendors/vendors';
import EventHandler from '../shared/js/dom/event-handler';

document.addEventListener('DOMContentLoaded', function(event) {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	// Komponenten
	components();

	// Basisfunktionalitäten etc.
	initial();

	// Seitenkopf
	header();

	// Seitenkopf
	navigation();

	// Seitenkopf
	footer();

	// Vendors
	vendors();

	//
	// DEV examples (nur zur Demonstration)
	//

	const devLoadingButtons        = SelectorEngine.find('#dev-section-buttons .deck button');
	const devLoadingButtonsTrigger = SelectorEngine.findOne('#dev-buttons-trigger-loading-state')

	EventHandler.on(devLoadingButtonsTrigger, 'click', (ev) => {
		ev.preventDefault();

		for (const element of devLoadingButtons) {
			if (!Manipulator.getDataAttribute(element, 'is-loading')) {
				Manipulator.setDataAttribute(element, 'is-loading', 'true');
			} else {
				Manipulator.removeDataAttribute(element, 'is-loading');
			}
		}
	});
});

// export default function main() {
// 	document.addEventListener("DOMContentLoaded", function(event) {
// 		if (processEnv === 'development') {
// 			console.log('DEVELOPMENT'); // eslint-disable-line no-console
// 		}
//
// 		components();
// 		core();
// 	});
// }
