// ------
// Public
// ------

/**
 * Offest-Position eines Elementes bestimmen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {(HTMLElement|Element|EventTarget)} parent
 * @returns {Object}
 */
const getOffset = (element, parent = null) => {
	const rectE = element.getBoundingClientRect();

	let offset;

	if (parent) {
		const rectP = parent.getBoundingClientRect();

		offset = {
			// top : Math.round(element.getBoundingClientRect().top - parent.getBoundingClientRect().top),
			// left: Math.round(element.getBoundingClientRect().left - parent.getBoundingClientRect().left)
			left: Math.round(rectE.left - rectP.left),
			top : Math.round(rectE.top - rectP.top)
		};
	} else {
		offset = {
			left: rectE.left + document.body.scrollLeft,
			top : rectE.top + document.body.scrollTop
		};
	}

	return offset;
};

// Export
export {
	getOffset
};

