/**
 * Components
 */

import Button         from '../../shared/components/button/button';
import Details        from '../../shared/components/details/details';
import Player         from '../../shared/components/player/player';
import Slider         from '../../shared/components/slider/slider';
import ScrollProgress from '../../shared/components/scroll-progress/scroll-progress';
import TabGroup       from '../../shared/components/tab-group/tab-group';
import MediaGallery   from '../../shared/components/media-gallery/media-gallery';
import Form           from '../../shared/components/form/form';

// import Chapters     from './chapters/chapters';
// import Video        from './video/video';

// ------
// Public
// ------

const components = () => {
	Button.init();

	Details.init();

	Slider.init(/*{
		breakpoints: {
			// Window >= 320x
			320: {
				spaceBetween : 16
			},
			// Window >= 768x
			768: {
				slidesPerView: 2,
				spaceBetween : 16
			},
			// Window >= 992px
			992: {
				slidesPerView: 4,
				spaceBetween : 32
			}
		}
	}*/);

	TabGroup.init({
		showIndicator: true
	});

	Player.init();

	MediaGallery.init();

	ScrollProgress.init();

	Form.init();
};

// Export
export default components;
