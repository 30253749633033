/**
 * Komponente ´Form´
 */

import {extend}         from '../../js/utils/extend';
import {scrollIntoView} from '../../js/utils/scroll';

import SelectorEngine from '../../js/dom/selector-engine';
import EventHandler   from '../../js/dom/event-handler';
import Data           from '../../js/dom/data';
import Manipulator    from '../../js/dom/manipulator';

import Spinner from '../spinner/spinner';

// -------
// Private
// -------

const NAME      = 'form';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	scrollInvalidField : true
};

// ------
// Public
// ------

/**
 *
 * @param {HTMLElement} form
 * @param {Object} o
 */
const validateFormNative = (form, o = {}) => {
	const _o   = extend({}, DEFAULTS, o);
			// var $form = $(this);
			//
			// $('.invalid-tooltip, .valid-tooltip', $form)
			// 	.parent()
			// 	.css('position', 'relative');

	EventHandler.on(form, `submit${EVENT_KEY}`, (ev) => {
		let elInvalid;

		if (form.checkValidity() === false) {
			ev.preventDefault();
			ev.stopPropagation();

			elInvalid = SelectorEngine.findOne('.form-control:invalid', form);

			if (elInvalid && _o.scrollInvalidField) {

				console.log(elInvalid);
				scrollIntoView(elInvalid, document.documentElement);
			}
		}

		Manipulator.addClass(form, 'was-validated');
	});
};

/**
 * Initialisierung.
 *
 * @param {Object} [o={}]
 */
const init = (o = {}) => {
	const _o    = extend({}, o);
	const forms = SelectorEngine.find('form[novalidate]');

	if (forms.length) {
		for (const element of forms) {
			if (!Data.get(element, `${DATA_KEY}initialized`)) {
				validateFormNative(element, _o);

				// Initialisierungsstatus setzen.
				Data.set(element, `${DATA_KEY}initialized`, true);
			}
		}
	}
};

// Export
export default {
	init  : init
};
