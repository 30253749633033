/* eslint-env es6 */

// -------
// Private
// -------

const elementMap = new Map();

// ------
// Public
// ------

const set = (element, key, instance) => {
	if (!elementMap.has(element)) {
		elementMap.set(element, new Map());
	}

	const instanceMap = elementMap.get(element);

	// if (!instanceMap.has(key) && instanceMap.size !== 0) {
	// 	if (processEnv === 'development') {
	// 		// eslint-disable-next-line no-console
	// 		console.error(`No more than one instance per element is permitted. Bound instance: ${Array.from(instanceMap.keys())[0]}.`);
	// 	}
	// 	return;
	// }

	instanceMap.set(key, instance);
};

const get = (element, key) => {
	if (elementMap.has(element)) {
		return elementMap.get(element).get(key) || null;
	}

	return null;
};

const remove = (element, key) => {
	if (!elementMap.has(element)) {
		return;
	}

	const instanceMap = elementMap.get(element);

	instanceMap.delete(key);

	// Elementreferenzen freigeben, wenn für ein Element keine Instanzen mehr
	// vorhanden sind.
	if (instanceMap.size === 0) {
		elementMap.delete(element);
	}
};

// Export
export default {
	set,
	get,
	remove
};
