/**
 * Code from Bootstrap (v5.0.2)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

// --------
// Public
// --------

const NODE_TEXT = 3;

const SelectorEngine = {
	find(selector, element = document.documentElement) {
		return [].concat(...Element.prototype.querySelectorAll.call(element, selector));
	},

	findOne(selector, element = document.documentElement) {
		return Element.prototype.querySelector.call(element, selector);
	},

	children(element, selector) {
		return [].concat(...element.children).filter(child => child.matches(selector));
	},

	parents(element, selector) {
		const parents = [];

		let ancestor = element.parentNode;

		while (ancestor && ancestor.nodeType === Node.ELEMENT_NODE && ancestor.nodeType !== NODE_TEXT) {
			if (ancestor.matches(selector)) {
				parents.push(ancestor);
			}

			ancestor = ancestor.parentNode;
		}

		return parents;
	},

	prev(element, selector) {
		let previous = element.previousElementSibling;

		while (previous) {
			if (previous.matches(selector)) {
				return [previous];
			}

			previous = previous.previousElementSibling;
		}

		return [];
	},

	next(element, selector) {
		let next = element.nextElementSibling;

		while (next) {
			if (next.matches(selector)) {
				return [next];
			}

			next = next.nextElementSibling;
		}

		return [];
	}
};

// Export
export default SelectorEngine;
