// ------------
// Requirements
// ------------

import {isElement} from './is';

// ------
// Public
// ------

/**
 * Root-Variablen auslesen und/oder setzen.
 *
 * @example
 * `--foo:'bar'`
 * getRootVar('foo'); // returns `bar`
 * getRootVar('fo'); // returns ''
 *
 * @param {String} key - Name der Rootvariable ohne `--`
 * @param {HTMLElement} [element=document.documentElement] - Kontext
 *
 * @returns {(boolean|String)}
 */
const getRootVar = (key, element = document.documentElement) => {
	return (isElement(element)) ? getComputedStyle(element).getPropertyValue(`--${key}`) : '';
};

/**
 * Root-Variablen auslesen und/oder setzen.
 *
 * @example
 * setRootVar('foo', 'bar'); // set `--foo:'bar'` and returns true
 *
 * @param {String} key - Name der Rootvariable ohne `--`
 * @param {String} val - Wert der Rootvariable
 * @param {HTMLElement} [element=document.documentElement] - Kontext
 * @returns {boolean}
 */
const setRootVar = (key, val , element = document.documentElement) => {
	let res = false;

	if (isElement(element)) {
		element.style.setProperty(`--${key}`, val);

		res = true;
	}

	return res;
};

// Export
export {
	getRootVar,
	setRootVar
};
