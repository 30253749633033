import {noop}       from './index';
import {getFeature} from './support/feature';

import {extend}       from './extend';
import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';

// -------
// Private
// -------

let featureSupport = null;

// ------
// Public
// ------

const lazyImages = (o = {}) =>  {
	if (!featureSupport) {
		featureSupport = getFeature();
	}

	const options = extend({}, {
		fallback: noop
	}, o);

	if (featureSupport.lazyLoading) {
		const images = SelectorEngine.find('img[loading="lazy"][data-src]');

		images.forEach(image => {
			const picture    = SelectorEngine.parents(image, 'picture')[0];
			const dataSrc    = Manipulator.getDataAttribute(image, 'src');
			const dataSrcSet = Manipulator.getDataAttribute(image, 'srcset');
			const dataSizes  = Manipulator.getDataAttribute(image, 'sizes');

			image.setAttribute('src', dataSrc);
			Manipulator.removeDataAttribute(image, 'src');

			if(dataSizes) {
				image.setAttribute('sizes', dataSizes);
				Manipulator.removeDataAttribute(image, 'sizes');
			}

			if(dataSrcSet){
				image.setAttribute('srcset', dataSrcSet);

				Manipulator.removeDataAttribute(image, 'srcset');
			}

			Manipulator.removeClass(image, 'lazyload');

			// Picture-Tag
			if(picture) {
				const sources = SelectorEngine.find('source', picture);

				sources.forEach(source => {
					const dataSrcSet = Manipulator.getDataAttribute(source, 'srcset');

					if(dataSrcSet) {
						source.setAttribute('srcset', dataSrcSet);
						Manipulator.removeDataAttribute(source, 'srcset');
					}

					Manipulator.removeClass(source, 'lazyload');
				});
			}
		});
	} else {
		options.fallback();
	}
};

export {
	lazyImages
};
